.legal-page {
    padding: 2rem;
    min-height: calc(100vh - 64px - 400px); /* Adjust based on header and footer height */
    background: #f8f9fa;
}

.legal-container {
    max-width: 1000px;
    margin: 0 auto;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.legal-container h1 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    border-bottom: 2px solid #4158D0;
    padding-bottom: 0.5rem;
}

.last-updated {
    color: #666;
    margin-bottom: 2rem;
    font-style: italic;
}

section {
    margin-bottom: 2rem;
}

section h2 {
    color: #4158D0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

section p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 1rem;
}

section ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

section ul li {
    color: #444;
    margin-bottom: 0.5rem;
    line-height: 1.4;
}

.contact-details {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
}

.contact-details p {
    margin-bottom: 0.5rem;
}

/* Sitemap specific styles */
.sitemap-list {
    list-style: none !important;
    margin-left: 0 !important;
}

.sitemap-list li {
    margin-bottom: 0.75rem;
}

.sitemap-list a {
    color: #4158D0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.sitemap-list a:hover {
    color: #C850C0;
    text-decoration: underline;
}
