.generated-campaigns-container {
    padding: 2rem;
    margin-top: 80px; /* Add margin to account for fixed header */
    min-height: calc(100vh - 80px); /* Adjust height to account for header */
    background: linear-gradient(135deg, #f6f9fc 0%, #f1f4f8 100%);
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    text-align: center;
}

.loading-container h2 {
    color: #2d3748;
    margin: 2rem 0 1rem;
    font-size: 1.8rem;
}

.loading-container p {
    color: #718096;
    font-size: 1.1rem;
}

.loading-animation {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    animation: bounce 0.5s ease-in-out infinite;
}

.circle:nth-child(2) {
    animation-delay: 0.1s;
}

.circle:nth-child(3) {
    animation-delay: 0.2s;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.campaigns-header {
    text-align: center;
    margin-bottom: 3rem;
}

.campaigns-header h1 {
    font-size: 2.5rem;
    color: #2d3748;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.campaigns-header p {
    font-size: 1.2rem;
    color: #718096;
    max-width: 600px;
    margin: 0 auto;
}

.packages-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 1rem;
}

.packages-grid {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.package-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    animation: fadeIn 0.5s ease-out forwards;
    height: auto;
    max-height: 600px;
    overflow-y: auto;
}

.package-header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    border-radius: 12px 12px 0 0;
}

.package-header h3 {
    font-size: 1.5rem;
    margin: 0;
    flex: 1;
    margin-bottom: 0.5rem;
}

.package-price {
    font-size: 2rem;
    font-weight: bold;
}

.package-content {
    display: flex;
    padding: 2rem;
    gap: 3rem;
}

.outcomes-section {
    flex: 1;
    margin-bottom: 1rem;
}

.outcomes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.outcome-item {
    background: #f7fafc;
    padding: 0.5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.outcome-item svg {
    color: #4158D0;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.outcome-label {
    color: #4a5568;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
}

.outcome-value {
    color: #2d3748;
    font-weight: bold;
    font-size: 1.25rem;
}

.media-allocations {
    flex: 1;
    margin-bottom: 1rem;
}

.allocations-list {
    display: grid;
    gap: 1rem;
}

.allocation-item {
    background: #f7fafc;
    padding: 0.5rem 0;
    border-radius: 8px;
}

.media-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.media-info svg {
    color: #4158D0;
    font-size: 1.25rem;
}

/* Campaign summary styles */
.campaign-summary {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.campaign-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.campaign-details p {
    color: #4a5568;
    margin: 0;
}

.campaign-details strong {
    color: #2d3748;
    display: block;
    margin-bottom: 0.5rem;
}

/* Responsive design */
@media (max-width: 1200px) {
    .package-content {
        flex-direction: column;
        gap: 2rem;
    }

    .outcomes-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .generated-campaigns-container {
        padding: 1rem;
    }

    .campaigns-header h1 {
        font-size: 2rem;
    }

    .package-header {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }

    .outcomes-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .package-content {
        padding: 1.5rem;
    }
}
