.campaign-planner {
  padding: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 120px); /* Account for header and some padding */
  overflow-y: auto;
}

.campaign-planner__header {
  text-align: center;
  margin-bottom: 1rem;
}

.campaign-planner__header h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.25rem;
}

.subtitle {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.campaign-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form-group label {
  font-weight: 500;
  color: #333;
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4158D0;
  box-shadow: 0 0 0 2px rgba(65, 88, 208, 0.1);
}

.form-group textarea {
  min-height: 60px;
  resize: vertical;
}

.input-help {
  color: #666;
  font-size: 0.8rem;
  margin-top: 0.1rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
}

.input-icon {
  position: relative;
}

.input-icon input {
  padding-left: 2rem;
  width: 100%;
}

.input-icon svg {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.9rem;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid #eee;
}

.primary-button,
.secondary-button {
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
}

.primary-button {
  background: linear-gradient(135deg, #4158D0, #C850C0);
  color: white;
  border: none;
}

.secondary-button {
  background: white;
  color: #4158D0;
  border: 2px solid #4158D0;
}

.primary-button:hover,
.secondary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.primary-button:active,
.secondary-button:active {
  transform: translateY(0);
}

.new-campaign-btn {
  background: linear-gradient(135deg, #4158D0, #C850C0);
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  transition: transform 0.2s;
}

.new-campaign-btn:hover {
  transform: translateY(-2px);
}

.campaigns-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.campaign-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.campaign-card:hover {
  transform: translateY(-4px);
}

.campaign-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.campaign-card__header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.campaign-card__actions {
  display: flex;
  gap: 0.5rem;
}

.edit-btn,
.delete-btn {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.edit-btn {
  color: #4158D0;
}

.delete-btn {
  color: #dc3545;
}

.edit-btn:hover,
.delete-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.campaign-card__dates {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.campaign-card__dates svg {
  margin-right: 0.5rem;
}

.campaign-card__details {
  font-size: 0.95rem;
}

.campaign-card__details p {
  margin: 0.5rem 0;
}

.platform-tag {
  display: inline-block;
  background: rgba(65, 88, 208, 0.1);
  color: #4158D0;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.85rem;
  margin: 0.25rem;
}

/* Loading and Error States */
.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.1rem;
}

.error-message {
  background-color: #fff3f3;
  color: #dc3545;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
}

.submit-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none !important;
}

/* Success Message */
.success-message {
  background-color: #f0fff4;
  color: #38a169;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
}

@media (max-width: 768px) {
  .campaign-planner {
    padding: 1rem;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    width: 100%;
  }

  .campaigns-list {
    grid-template-columns: 1fr;
  }
}
