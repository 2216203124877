.faq-section {
    padding: 4rem 0;
    background: #f8f9fa;
}

.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.faq-container h2 {
    text-align: center;
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    position: relative;
}

.faq-container h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 1.5rem;
}

.faq-item {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.faq-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-item.active {
    border-left: 4px solid #4158D0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.faq-question h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    font-weight: 500;
}

.faq-icon {
    color: #4158D0;
    transition: transform 0.3s ease;
}

.faq-item.active .faq-icon {
    transform: rotate(180deg);
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: 0;
}

.faq-answer.show {
    max-height: 300px;
    margin-top: 1rem;
    opacity: 1;
}

.faq-answer p {
    margin: 0;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .faq-grid {
        grid-template-columns: 1fr;
    }

    .faq-container {
        padding: 0 1rem;
    }

    .faq-container h2 {
        font-size: 2rem;
    }

    .faq-item {
        padding: 1rem;
    }
}

@media (max-width: 500px) {
    .faq-section {
        padding: 2rem 0;
    }

    .faq-container h2 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    .faq-question h3 {
        font-size: 1rem;
    }
}
