.profile-container {
    padding: 2rem;
    max-width: 800px;
    margin: 2rem auto;
    min-height: calc(100vh - 120px);
}

.profile-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2.5rem;
}

.profile-header {
    text-align: center;
    margin-bottom: 2.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eee;
}

.profile-avatar {
    width: 120px;
    height: 120px;
    background: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    color: #4a148c;
}

.profile-header h1 {
    color: #333;
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
}

.profile-details {
    margin: 2rem 0;
}

.profile-info-item {
    display: flex;
    align-items: center;
    padding: 1.2rem;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s;
}

.profile-info-item:hover {
    background-color: #f8f9fa;
}

.profile-info-item:last-child {
    border-bottom: none;
}

.profile-info-item svg {
    color: #4a148c;
    margin-right: 1.2rem;
    width: 24px;
    height: 24px;
}

.profile-info-item div {
    flex: 1;
}

.profile-info-item h3 {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.profile-info-item p {
    margin: 0.4rem 0 0;
    color: #333;
    font-size: 1.1rem;
    font-weight: 500;
}

.logout-button {
    width: 100%;
    padding: 1rem;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    transition: all 0.2s;
    margin-top: 2rem;
}

.logout-button:hover {
    background: #c82333;
    transform: translateY(-1px);
}

.logout-button:active {
    transform: translateY(0);
}

.profile-loading {
    text-align: center;
    padding: 3rem;
    color: #666;
    font-size: 1.1rem;
}

.profile-error {
    text-align: center;
    padding: 3rem;
    color: #dc3545;
    font-size: 1.1rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 600px;
}
