/* Base styles */
:root {
  --primary-gradient: linear-gradient(135deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  --primary-color: #4158D0;
  --secondary-color: #C850C0;
  --accent-color: #FFCC70;
  --text-color: #333;
  --bg-color: #f8f9ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

body, #root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
}

.home-page {
  background: #fff;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
}

/* Hero Section */
.hero {
  margin: 0;
  padding: 40px;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--primary-gradient);
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 60px;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.hero-content {
  flex: 0 1 40%;
  color: white;
  position: relative;
  z-index: 1;
  text-align: left;
}

.hero-content h1 {
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.2rem;
  background: linear-gradient(120deg, #ffffff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hero-content p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 90%;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 0.8rem;
}

.primary-button,
.secondary-button {
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
}

.primary-button {
  background: white;
  color: var(--primary-color);
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.secondary-button {
  background: transparent;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  background: #f8f9ff;
}

.secondary-button:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
  border-color: white;
}

@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .hero-content {
    text-align: center;
    padding: 0 1rem;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 0.9rem;
    margin: 0 auto 1.5rem;
  }

  .hero-buttons {
    justify-content: center;
    flex-wrap: wrap;
  }

  .primary-button,
  .secondary-button {
    min-width: 140px;
    padding: 0.7rem 1.5rem;
  }
}

/* Features Section */
.features {
  padding: 60px 40px;
  background: white;
  position: relative;
  margin-top: -20px;
  width: 100%;
}

.features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features {
    padding: 40px 20px;
    margin-top: -15px;
  }
  
  .features h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}

/* Media Channels Section */
.media-channels {
  padding: 100px 40px;
  background: var(--bg-color);
  width: 100%;
}

.media-channels h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.channels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.channel-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.channel-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-gradient);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
}

.channel-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.channel-card:hover::before {
  opacity: 0.05;
}

.channel-icon {
  width: 60px;
  height: 60px;
  background: var(--primary-gradient);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.channel-icon svg {
  width: 24px;
  height: 24px;
  color: white;
}

.channel-card h3 {
  font-size: 1.3rem;
  margin: 0;
  position: relative;
  z-index: 1;
  color: var(--text-color);
}

.channel-card p {
  color: #666;
  line-height: 1.6;
  position: relative;
  z-index: 1;
  margin: 0;
}

/* Media Channels Slider */
.media-channels-slider {
  position: relative;
  flex: 0 1 50%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 1;
  width: 100%;
}

.media-channels-slider .slick-prev,
.media-channels-slider .slick-next {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 2;
  transition: all 0.3s ease;
}

.media-channels-slider .slick-prev {
  left: -50px;
}

.media-channels-slider .slick-next {
  right: -50px;
}

.media-channels-slider .slick-prev:hover,
.media-channels-slider .slick-next:hover {
  background: rgba(255, 255, 255, 0.3);
}

.media-channels-slider .slick-prev:before,
.media-channels-slider .slick-next:before {
  font-size: 24px;
  opacity: 1;
}

.media-channels-slider .slick-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.slider-item {
  padding: 0 8px;
  height: 220px;
}

.media-channels-slider .slick-slider {
  overflow: visible;
  padding: 0 50px;
}

.media-channels-slider .slick-list {
  overflow: hidden;
}

.media-channels-slider .channel-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  transform-origin: center bottom;
}

.media-channels-slider .channel-card:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.media-channels-slider .channel-card .channel-icon {
  background: none;
  font-size: 2rem;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.media-channels-slider .channel-card h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: white;
}

.media-channels-slider .channel-card p {
  font-size: 0.9rem;
  opacity: 0.9;
  margin: 0;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.9);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .media-channels-slider {
    flex: 0 1 50%;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .media-channels-slider {
    flex: none;
    width: 100%;
    margin-top: 3rem;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;
  }
}

/* Slick Slider Custom Styles */
.slick-slider {
  overflow: visible;
  margin: -15px;
  padding: 15px;
}

.slick-list {
  overflow: visible;
  margin: 0 -15px;
}

.slick-track {
  display: flex !important;
  padding: 15px 0;
}

.slick-slide {
  height: inherit !important;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  padding: 0 15px;
}

.slick-slide.slick-active {
  opacity: 1;
}

.slick-slide > div {
  height: 100%;
}

.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  color: white;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
  transform: scale(1.2);
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  z-index: 2;
  transition: all 0.3s ease;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
}

/* CTA Section */
.cta {
  padding: 100px 40px;
  background: linear-gradient(135deg, rgba(65, 88, 208, 0.05) 0%, rgba(200, 80, 192, 0.05) 46%, rgba(255, 204, 112, 0.05) 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
}

.cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%234a148c' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.cta-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.cta h2 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.cta p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.cta-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 3rem;
}

.cta-primary,
.cta-secondary {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  min-width: 200px;
  justify-content: center;
}

.cta-primary {
  background: var(--primary-gradient);
  color: white;
  border: none;
  box-shadow: 0 4px 15px rgba(65, 88, 208, 0.2);
}

.cta-secondary {
  background: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.button-icon {
  font-size: 1.2rem;
}

.cta-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(65, 88, 208, 0.3);
}

.cta-secondary:hover {
  transform: translateY(-2px);
  background: rgba(65, 88, 208, 0.05);
}

.cta-features {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
}

.cta-feature {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #666;
  font-size: 1.1rem;
}

.cta-feature svg {
  color: var(--primary-color);
  font-size: 1.3rem;
}

@media (max-width: 768px) {
  .cta {
    padding: 60px 20px;
  }

  .cta h2 {
    font-size: 2.5rem;
  }

  .cta p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .cta-primary,
  .cta-secondary {
    width: 100%;
    min-width: unset;
  }

  .cta-features {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .hero {
    padding: 60px 30px;
  }

  .hero-content {
    flex: 0 1 45%;
  }

  .media-channels-slider {
    flex: 0 1 55%;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 60px 20px;
    gap: 3rem;
  }

  .hero-content, 
  .media-channels-slider {
    flex: 0 1 auto;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .media-channels-slider {
    padding: 0;
  }

  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }

  .channel-card {
    padding: 1rem;
  }

  .channel-icon {
    font-size: 1.5rem;
  }

  .channel-card h3 {
    font-size: 1rem;
  }

  .channel-card p {
    font-size: 0.8rem;
  }
}

/* Contact Section */
.contact-section {
  position: relative;
  padding: 5rem 2rem;
  background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
  color: white;
  z-index: 1;
}

.contact-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;
}

.contact-content {
  text-align: center;
  background: transparent;
}

.contact-content h2 {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-subtitle {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 3rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contact-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
  background: transparent;
}

.contact-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.contact-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.contact-icon {
  font-size: 2rem;
  color: white;
  margin-bottom: 1.5rem;
}

.contact-card h3 {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 0.5rem;
}

.contact-card p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.contact-link {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: white;
  color: #4158D0;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.contact-link:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-2px);
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
  background: transparent;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-links a:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .contact-section {
    padding: 4rem 1rem;
  }

  .contact-content h2 {
    font-size: 2rem;
  }

  .contact-cards {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .contact-card {
    padding: 1.5rem;
  }
}
