/* src/index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #f5f5f5;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
}

a {
  text-decoration: none;
  color: #007bff;
}

ul {
  list-style: none;
  padding: 0;
}

input, button {
  padding: 8px;
  margin: 5px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
