.packages-container {
    width: 100%;
}

.packages-container h2 {
    color: #2d3748;
    margin-bottom: 2rem;
    text-align: center;
}

.packages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.package-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;
}

.package-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.package-card.selected {
    border: 2px solid #4158D0;
}

/* Custom Package Styles */
.custom-package {
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.custom-package .package-header {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    width: 100%;
}

.custom-package .package-header h3 {
    color: black;
}

.custom-package .price-label {
    font-size: 1.2rem;
    opacity: 0.9;
    color: white;
}

.custom-package-intro {
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-package-icon {
    font-size: 3rem;
    color: #667eea;
    margin-bottom: 1.5rem;
}

.custom-package-intro h4 {
    color: #2d3748;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.custom-package-intro p {
    color: #4a5568;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.custom-package-form {
    padding: 1.5rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.custom-package-form .form-group {
    margin-bottom: 1rem;
}

.custom-package-form input,
.custom-package-form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s;
}

.custom-package-form textarea {
    min-height: 100px;
    resize: vertical;
}

.custom-package-form input:focus,
.custom-package-form textarea:focus {
    outline: none;
    border-color: #667eea;
    box-shadow: 0 0 0 3px rgba(102, 126, 234, 0.1);
}

.form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.cancel-btn,
.submit-btn {
    flex: 1;
    padding: 0.75rem;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.cancel-btn {
    background: #e2e8f0;
    color: #4a5568;
    border: none;
}

.cancel-btn:hover {
    background: #cbd5e0;
}

.submit-btn {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    border: none;
}

.submit-btn:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.package-header {
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    padding: 1.5rem;
    text-align: center;
}

.package-header h3 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
}

.package-price {
    font-size: 2rem;
    font-weight: bold;
}

.package-content {
    padding: 1.5rem;
}

.outcomes-section,
.media-allocations,
.features-section {
    margin-bottom: 2rem;
}

.outcomes-section h4,
.media-allocations h4,
.features-section h4 {
    color: #2d3748;
    margin-bottom: 1rem;
}

.outcomes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.outcome-item {
    background: #f7fafc;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.outcome-item svg {
    color: #4158D0;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.outcome-label {
    color: #4a5568;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
}

.outcome-value {
    color: #2d3748;
    font-weight: bold;
}

.allocations-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.allocation-item {
    background: #f7fafc;
    padding: 1rem;
    border-radius: 8px;
}

.media-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.media-info svg {
    color: #4158D0;
}

.media-type {
    color: #4a5568;
    flex: 1;
}

.allocation-amount {
    color: #2d3748;
    font-weight: bold;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0;
    color: #4a5568;
}

.features-list li svg {
    color: #48BB78;
}

.select-package-btn {
    width: 100%;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: all 0.2s;
    margin-top: 1.5rem;
}

.select-package-btn:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .packages-grid {
        grid-template-columns: 1fr;
    }

    .outcomes-grid {
        grid-template-columns: 1fr;
    }
}
