.footer {
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    padding: 4rem 0 0;
    margin-top: 4rem;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.footer-section {
    margin-bottom: 2rem;
    background: transparent;
}

.footer-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.footer-section h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}

.footer-description {
    line-height: 1.6;
    margin-bottom: 1.5rem;
    opacity: 0.9;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background: transparent;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
    background: transparent;
}

.footer-section ul li a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 0.95rem;
    background: transparent;
}

.footer-section ul li a:hover {
    color: white;
    padding-left: 5px;
}

.footer-section:nth-child(3) ul {
    columns: 2;
    column-gap: 2rem;
}

.social-links {
    display: flex;
    gap: 1rem;
}

.social-links a {
    background: rgba(255, 255, 255, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: all 0.3s ease;
}

.social-links a:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

.contact-info {
    background: transparent;
}

.contact-info li {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    line-height: 1.4;
    background: transparent;
}

.contact-label {
    font-weight: 500;
    margin-bottom: 0.2rem;
    color: rgba(255, 255, 255, 0.9);
    background: transparent;
}

.contact-info a {
    color: white;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.3s ease;
    background: transparent;
}

.contact-info a:hover {
    opacity: 1;
    color: white;
}

.contact-info address {
    font-style: normal;
    opacity: 0.8;
    background: transparent;
}

.footer-bottom {
    background: rgba(0, 0, 0, 0.1);
    padding: 1.5rem 0;
    margin-top: 3rem;
}

.footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.footer-links {
    display: flex;
    gap: 2rem;
}

.footer-links a {
    color: white;
    text-decoration: none;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.footer-links a:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .footer-bottom-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-links {
        justify-content: center;
    }

    .footer-section:nth-child(3) ul {
        columns: 1;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 3rem 0 0;
    }

    .footer-content {
        grid-template-columns: 1fr;
    }

    .footer-links {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }
}
