.agency-registration-page {
    min-height: 100vh;
    background: #fff;
    margin-top: 60px; /* Height of the header */
}

.hero-section {
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    padding: 1rem 2rem;
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.hero-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.hero-text {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-text h1 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
    line-height: 1.2;
    text-align: center;
}

.hero-text p {
    font-size: 1rem;
    margin: 0;
    opacity: 0.9;
    text-align: center;
    max-width: 600px;
}

.hero-stats {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.stat-item {
    background: white;
    border-radius: 6px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    flex: 1;
    min-width: 180px;
    max-width: 200px;
}

.stat-icon-wrapper {
    width: 32px;
    height: 32px;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.stat-icon {
    font-size: 1rem;
    color: white;
}

.stat-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333;
}

.stat-content h3 {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    line-height: 1;
    color: #333;
    text-align: center;
}

.stat-content p {
    font-size: 0.75rem;
    margin: 0.2rem 0 0 0;
    opacity: 0.8;
    color: #666;
    line-height: 1;
    text-align: center;
}

.stat-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agency-registration-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1400px;
    margin: 0 auto;
    padding: 4rem 2rem;
    gap: 4rem;
}

.form-section {
    flex: 1;
    max-width: 500px;
}

.form-container {
    width: 100%;
    background: white;
    padding: 2.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.why-partner-section {
    flex: 0.8;
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 10px;
    position: sticky;
    top: 2rem;
}

.info-section {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.info-content {
    width: 100%;
}

.section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 3rem;
}

.benefits-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.benefit-card {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.benefit-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-wrapper {
    width: 50px;
    height: 50px;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.benefit-icon {
    font-size: 1.5rem;
    color: white;
}

.benefit-content {
    flex: 1;
}

.benefit-content h3 {
    font-size: 1.2rem;
    color: #333;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
}

.benefit-content p {
    font-size: 0.95rem;
    color: #666;
    margin: 0;
    line-height: 1.5;
}

.agency-types {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
}

.agency-types h4 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 500;
}

.agency-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
}

.agency-tag {
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    padding: 0.5rem 1.2rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
}

.launch-benefits-section {
    padding: 4rem 0;
    background: #fff;
}

.launch-benefits-section .section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.launch-benefits-section .section-title {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 0.5rem;
    text-align: center;
}

.launch-benefits-section .section-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 3rem;
    text-align: center;
}

.launch-benefits-section .benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.launch-benefits-section .benefit-card {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.launch-benefits-section .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.launch-benefits-section .icon-wrapper {
    width: 60px;
    height: 60px;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.launch-benefits-section .benefit-icon {
    font-size: 1.8rem;
    color: white;
}

.launch-benefits-section .benefit-content h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    color: #333;
}

.launch-benefits-section .benefit-content p {
    font-size: 1rem;
    margin: 0;
    color: #666;
    line-height: 1.6;
}

.why-partner-section {
    padding: 4rem 0;
    background: #f8f9fa;
    margin-top: 0;
}

.why-partner-section .section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.why-partner-section .section-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
    text-align: center;
}

.why-partner-section .section-subtitle {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 1.5rem;
    text-align: center;
}

.why-partner-section .benefits-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 3rem;
}

.why-partner-section .benefit-card {
    padding: 1.2rem;
    background: white;
    border-radius: 8px;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.why-partner-section .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.why-partner-section .icon-wrapper {
    width: 40px;
    height: 40px;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 0.8rem;
}

.why-partner-section .benefit-icon {
    font-size: 1.2rem;
    color: white;
}

.why-partner-section .benefit-content h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: #333;
}

.why-partner-section .benefit-content p {
    font-size: 0.9rem;
    margin: 0;
    color: #666;
    line-height: 1.4;
}

.why-partner-section .agency-types {
    text-align: center;
    margin-top: 1.2rem;
}

.why-partner-section .agency-types h4 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.8rem;
}

.why-partner-section .agency-tags {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}

.why-partner-section .agency-tag {
    background: white;
    padding: 0.4rem 0.8rem;
    border-radius: 20px;
    color: #666;
    font-size: 0.85rem;
    transition: all 0.2s ease;
    border: 1px solid #eee;
    margin: 0.3rem;
}

.why-partner-section .agency-tag:hover {
    background: #f8f9fa;
    color: #333;
}

.success-stories-section {
    padding: 4rem 0;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
}

.success-stories-section .section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.success-stories-section .section-title {
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 0.5rem;
    color: white;
}

.success-stories-section .section-subtitle {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 3rem;
    color: rgba(255, 255, 255, 0.9);
}

.stories-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.story-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 2rem;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease;
}

.story-card:hover {
    transform: translateY(-5px);
}

.quote-icon {
    color: rgba(255, 255, 255, 0.2);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.quote {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    color: rgba(255, 255, 255, 0.9);
}

.author {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 1rem;
}

.author-info h4 {
    font-size: 1.1rem;
    margin: 0 0 0.2rem 0;
    color: white;
}

.author-info p {
    font-size: 0.9rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
}

.metrics {
    text-align: right;
}

.metric {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.metric strong {
    font-size: 1.2rem;
    color: white;
}

.metric span {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 992px) {
    .launch-benefits-section {
        padding: 3rem 1rem;
    }

    .launch-benefits-section .benefits-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .launch-benefits-section .section-title {
        font-size: 1.8rem;
    }

    .launch-benefits-section .section-subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    .launch-benefits-section .benefit-card {
        padding: 1.5rem;
    }

    .launch-benefits-section .icon-wrapper {
        width: 50px;
        height: 50px;
        margin-bottom: 1rem;
    }

    .launch-benefits-section .benefit-icon {
        font-size: 1.5rem;
    }

    .launch-benefits-section .benefit-content h3 {
        font-size: 1.2rem;
        margin-bottom: 0.75rem;
    }
}

@media (max-width: 992px) {
    .hero-section {
        padding: 1rem;
    }

    .hero-text h1 {
        font-size: 2rem;
    }

    .hero-stats {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .stat-item {
        max-width: 100%;
    }

    .agency-registration-container {
        flex-direction: column;
        padding: 2rem 1rem;
        gap: 2rem;
    }

    .form-section,
    .why-partner-section {
        max-width: 100%;
    }

    .why-partner-section {
        position: static;
        flex: 1;
    }

    .info-content {
        text-align: center;
    }

    .benefit-card {
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 2rem;
    }

    .icon-wrapper {
        margin-bottom: 1rem;
    }

    .agency-tags {
        justify-content: center;
    }

    .form-container {
        max-width: 100%;
    }

    .launch-benefits {
        margin-top: 2rem;
        padding-top: 1.5rem;
    }

    .launch-benefits .section-title {
        font-size: 1.6rem;
    }

    .launch-benefits .benefits-grid {
        gap: 1rem;
    }
}

@media (max-width: 992px) {
    .why-partner-section {
        padding: 3rem 1rem;
    }

    .why-partner-section .benefits-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .why-partner-section .section-title {
        font-size: 1.8rem;
    }

    .why-partner-section .section-subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    .why-partner-section .benefit-card {
        padding: 1.5rem;
    }
}

@media (max-width: 992px) {
    .stories-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .success-stories-section {
        padding: 3rem 1rem;
    }

    .success-stories-section .section-title {
        font-size: 1.8rem;
    }

    .success-stories-section .section-subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.dialog {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialog h3 {
    margin-top: 0;
    color: #333;
}

.dialog p {
    margin: 15px 0;
    color: #666;
    line-height: 1.4;
}

.dialog button {
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: 500;
}

.dialog.success h3 {
    color: #28a745;
}

.dialog.success button {
    background-color: #28a745;
}

.dialog.success button:hover {
    background-color: #218838;
}

.dialog.error h3 {
    color: #dc3545;
}

.dialog.error button {
    background-color: #dc3545;
}

.dialog.error button:hover {
    background-color: #c82333;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.dialog-overlay {
    animation: fadeIn 0.3s ease-out;
}

.dialog {
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}