.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chat-trigger {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.chat-trigger::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(-100%);
    transition: transform 0.5s ease;
}

.chat-trigger:hover::before {
    transform: translateX(0);
}

.chat-trigger .robot-icon {
    font-size: 28px;
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0px); }
}

.chat-window {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 380px;
    height: 550px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: slideIn 0.3s ease;
}

@keyframes slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.chat-header {
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header-text {
    display: flex;
    flex-direction: column;
}

.header-text h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.ai-status {
    font-size: 12px;
    opacity: 0.8;
}

.close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.close-button:hover {
    opacity: 1;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f8f9fa;
}

.message {
    max-width: 85%;
    padding: 12px 16px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1.5;
    position: relative;
    transition: all 0.3s ease;
}

.message.bot {
    align-self: flex-start;
    background: white;
    color: #1a1a1a;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 40px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.message.user {
    align-self: flex-end;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.bot-indicator {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
}

.typing-indicator {
    display: flex;
    gap: 4px;
    padding: 8px 0;
}

.typing-indicator span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4158D0;
    animation: typing 1.4s infinite;
    opacity: 0.4;
}

.typing-indicator span:nth-child(2) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-4px); }
}

.message-options {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
}

.ai-button {
    background: white;
    border: 1px solid #4158D0;
    color: #4158D0;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 6px;
}

.ai-button:hover {
    background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
    color: white;
    border-color: transparent;
    transform: translateY(-2px);
}

.chat-input {
    padding: 15px;
    border-top: 1px solid #eee;
    display: flex;
    gap: 10px;
    background: white;
}

.chat-input input {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 24px;
    outline: none;
    font-size: 14px;
    transition: all 0.3s ease;
}

.chat-input input:focus {
    border-color: #4158D0;
    box-shadow: 0 0 0 2px rgba(65, 88, 208, 0.1);
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;
    background: white;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.contact-form input {
    padding: 10px 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.contact-form input:focus {
    border-color: #4158D0;
    box-shadow: 0 0 0 2px rgba(65, 88, 208, 0.1);
}

@media (max-width: 480px) {
    .chat-window {
        width: calc(100% - 40px);
        height: calc(100vh - 120px);
        bottom: 80px;
    }

    .message {
        max-width: 90%;
    }
}
