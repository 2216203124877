.campaign-planning-page {
    width: 100%;
    min-height: calc(100vh - 80px);
    margin-top: 80px;
    padding: 0 2rem;
}

.campaign-planning-page .content-wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.campaign-planning-page .content-wrapper.show-packages {
    max-width: 1400px;
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 4rem;
    align-items: start;
}

.campaign-planning-page .ai-campaign-form {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.campaign-planning-page .ai-campaign-form h1 {
    color: #2d3748;
    font-size: 1.875rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.campaign-planning-page .ai-subtitle {
    color: #718096;
    margin-bottom: 2rem;
}

.campaign-planning-page .show-packages .ai-campaign-form {
    position: sticky;
    top: 100px;
}

.campaign-planning-page .form-group {
    margin-bottom: 1.5rem;
}

.campaign-planning-page .form-group label {
    display: block;
    color: #4a5568;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.campaign-planning-page .form-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 1rem;
    transition: all 0.2s;
}

.campaign-planning-page .form-input:focus {
    outline: none;
    border-color: #4158D0;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.campaign-planning-page .budget-options,
.campaign-planning-page .goal-options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.campaign-planning-page .budget-option,
.campaign-planning-page .goal-option {
    cursor: pointer;
}

.campaign-planning-page .budget-option input[type="radio"],
.campaign-planning-page .goal-option input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.campaign-planning-page .budget-option input[type="radio"]:checked + .budget-label,
.campaign-planning-page .goal-option input[type="radio"]:checked + .goal-content {
    border-color: #4158D0;
    background: linear-gradient(45deg, rgba(65, 88, 208, 0.05) 0%, rgba(200, 80, 192, 0.05) 100%);
}

.campaign-planning-page .budget-label,
.campaign-planning-page .goal-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    transition: all 0.2s;
}

.campaign-planning-page .ai-generated-packages {
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    visibility: hidden;
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 0;
    height: fit-content;
    align-self: start;
}

.campaign-planning-page .packages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;
}

.campaign-planning-page .packages-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.campaign-planning-page .ai-generated-packages.visible {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
}

.campaign-planning-page .ai-generated-packages {
    margin-top: 3rem;
}

.campaign-planning-page .ai-generated-packages h2 {
    color: #2d3748;
    margin-bottom: 1.5rem;
    text-align: center;
}

.campaign-planning-page .step-indicator {
    margin-bottom: 2rem;
    position: relative;
}

.campaign-planning-page .step-progress {
    position: absolute;
    top: 50%;
    height: 4px;
    background: linear-gradient(90deg, #4158D0 0%, #C850C0 100%);
    transition: width 0.3s ease;
    z-index: 1;
}

.campaign-planning-page .step-numbers {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.campaign-planning-page .step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transition: all 0.3s ease;
}

.campaign-planning-page .step-number.active {
    border-color: #4158D0;
    background: #4158D0;
    color: #fff;
}

.campaign-planning-page .step-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.campaign-planning-page .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.campaign-planning-page .media-types-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.campaign-planning-page .media-type-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    transition: all 0.3s ease;
}

.campaign-planning-page .media-type-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.campaign-planning-page .media-type-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.campaign-planning-page .media-type-header h3 {
    margin: 0;
    font-size: 1.1rem;
}

.campaign-planning-page .checkbox-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.campaign-planning-page .checkbox-container input {
    opacity: 0;
    position: absolute;
}

.campaign-planning-page .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.campaign-planning-page .checkbox-container input:checked ~ .checkmark {
    background-color: #4158D0;
    border-color: #4158D0;
}

.campaign-planning-page .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.campaign-planning-page .prev-button,
.campaign-planning-page .next-button,
.campaign-planning-page .submit-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
}

.campaign-planning-page .prev-button {
    background: #f5f5f5;
    color: #333;
}

.campaign-planning-page .next-button,
.campaign-planning-page .submit-button {
    background: linear-gradient(90deg, #4158D0 0%, #C850C0 100%);
    color: #fff;
}

.campaign-planning-page .prev-button:hover,
.campaign-planning-page .next-button:hover,
.campaign-planning-page .submit-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.campaign-planning-page .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.campaign-planning-page .budget-breakdown {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 1.5rem;
}

.campaign-planning-page .budget-breakdown h3 {
    margin-top: 0;
    margin-bottom: 1rem;
}

.campaign-planning-page .no-packages {
    text-align: center;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 2rem 0;
}

.campaign-planning-page .campaign-header {
    text-align: center;
    margin-bottom: 3rem;
    max-width: 800px;
    margin: 0 auto;
}

.campaign-planning-page .campaign-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 700;
}

.campaign-planning-page .campaign-header p {
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
}

.campaign-planning-page .form-wrapper {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.campaign-planning-page .step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    position: relative;
    padding: 0 2rem;
    gap: 4rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.campaign-planning-page .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    flex: 1;
    max-width: 120px;
}

.campaign-planning-page .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 18px;
    left: calc(50% + 18px);
    width: calc(100% + 4rem - 36px);
    height: 2px;
    background: #e0e0e0;
}

.campaign-planning-page .step.active:not(:last-child)::after {
    background: linear-gradient(to right, #4158D0, #e0e0e0);
}

.campaign-planning-page .step.completed:not(:last-child)::after {
    background: #4158D0;
}

.campaign-planning-page .step-number {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 0.5rem;
    transition: all 0.3s ease;
    position: relative;
}

.campaign-planning-page .step.active .step-number {
    background: linear-gradient(45deg, #4158D0, #C850C0);
    color: white;
    border-color: transparent;
    box-shadow: 0 4px 10px rgba(65, 88, 208, 0.2);
}

.campaign-planning-page .step.completed .step-number {
    background: #4158D0;
    color: white;
    border-color: transparent;
}

.campaign-planning-page .step-label {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
    white-space: nowrap;
}

.campaign-planning-page .step.active .step-label {
    color: #4158D0;
    font-weight: 500;
}

.campaign-planning-page .form-container {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
}

.campaign-planning-page .media-types-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.campaign-planning-page .select-all-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: linear-gradient(45deg, #4158D0, #C850C0);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.campaign-planning-page .select-all-btn:hover {
    opacity: 0.9;
}

.campaign-planning-page .media-types-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.campaign-planning-page .media-type-card {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.campaign-planning-page .media-type-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.campaign-planning-page .media-type-card.selected {
    border-color: #4158D0;
    background: linear-gradient(to right bottom, rgba(65, 88, 208, 0.05), rgba(200, 80, 192, 0.05));
}

.campaign-planning-page .media-type-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: linear-gradient(45deg, #4158D0, #C850C0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.2rem;
}

.campaign-planning-page .media-type-content {
    flex: 1;
}

.campaign-planning-page .media-type-content h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
    color: #333;
}

.campaign-planning-page .media-type-content p {
    margin: 0;
    font-size: 0.9rem;
    color: #666;
}

.campaign-planning-page .media-type-stats {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.campaign-planning-page .stat-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.campaign-planning-page .stat-value {
    font-weight: 600;
    color: #4158D0;
}

.campaign-planning-page .stat-label {
    font-size: 0.8rem;
    color: #666;
}

.campaign-planning-page .custom-checkbox {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 20px;
    height: 20px;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.campaign-planning-page .media-type-card.selected .custom-checkbox {
    background: linear-gradient(45deg, #4158D0, #C850C0);
    border-color: transparent;
}

.campaign-planning-page .media-type-card.selected .custom-checkbox::after {
    content: '✓';
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
}

.campaign-planning-page .button-group {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
}

.campaign-planning-page .nav-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.campaign-planning-page .nav-button.prev {
    background: #f5f5f5;
    color: #666;
}

.campaign-planning-page .nav-button.next {
    background: linear-gradient(45deg, #4158D0, #C850C0);
    color: white;
}

.campaign-planning-page .nav-button:hover {
    transform: translateY(-1px);
}

.campaign-planning-page .nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
}

.campaign-planning-page .icon {
    font-size: 0.9rem;
}

.campaign-planning-page .checkbox-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 0.5rem;
}

.campaign-planning-page .checkbox-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.campaign-planning-page .checkbox-item input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border: 2px solid #d1d5db;
    border-radius: 4px;
    cursor: pointer;
}

.campaign-planning-page .checkbox-item label {
    margin-bottom: 0;
    cursor: pointer;
}

.campaign-planning-page .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.campaign-planning-page .form-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.campaign-planning-page .form-group:last-child {
    margin-bottom: 0;
}

.campaign-planning-page label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
}

.campaign-planning-page label.required::after {
    content: '*';
    color: #dc2626;
    margin-left: 4px;
}

.campaign-planning-page input[type="text"],
.campaign-planning-page input[type="number"],
.campaign-planning-page input[type="date"],
.campaign-planning-page select,
.campaign-planning-page textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.campaign-planning-page input[type="text"]:focus,
.campaign-planning-page input[type="number"]:focus,
.campaign-planning-page input[type="date"]:focus,
.campaign-planning-page select:focus,
.campaign-planning-page textarea:focus {
    outline: none;
    border-color: #4f46e5;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.campaign-planning-page .ai-suggestions {
    background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
    border-radius: 12px;
    padding: 1.5rem;
    color: white;
    margin-top: 2rem;
}

.campaign-planning-page .ai-suggestions h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.campaign-planning-page .ai-suggestions h3::before {
    content: '🤖';
    font-size: 1.2em;
}

.campaign-planning-page .ai-suggestions p {
    font-size: 0.95rem;
    line-height: 1.5;
    opacity: 0.9;
}

.campaign-planning-page .media-preferences {
    margin-top: 2rem;
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.4s ease-out;
}

.campaign-planning-page .media-preferences h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #4158D0, #C850C0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.campaign-planning-page .platform-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.campaign-planning-page .platform-card {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid #eee;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.campaign-planning-page .platform-card:hover {
    transform: translateY(-2px);
    border-color: #4158D0;
    box-shadow: 0 8px 20px rgba(65, 88, 208, 0.15);
}

.campaign-planning-page .platform-card.selected {
    border-color: #4158D0;
    background: linear-gradient(135deg, rgba(65, 88, 208, 0.05), rgba(200, 80, 192, 0.05));
}

.campaign-planning-page .platform-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    background: linear-gradient(135deg, #4158D0, #C850C0);
    color: white;
}

.campaign-planning-page .platform-info {
    flex-grow: 1;
}

.campaign-planning-page .platform-name {
    font-weight: 600;
    color: #333;
    margin-bottom: 0.25rem;
}

.campaign-planning-page .platform-reach {
    font-size: 0.85rem;
    color: #666;
}

.campaign-planning-page .duration-input {
    position: relative;
    display: flex;
    align-items: center;
}

.campaign-planning-page .duration-input input {
    padding-right: 4rem;
}

.campaign-planning-page .duration-suffix {
    position: absolute;
    right: 2.5rem;
    color: #718096;
}

.campaign-planning-page .duration-icon {
    position: absolute;
    right: 1rem;
    color: #718096;
}

.campaign-planning-page .submit-button {
    width: 100%;
    padding: 1rem;
    background: linear-gradient(90deg, #4158D0 0%, #C850C0 100%);
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: all 0.2s;
}

.campaign-planning-page .submit-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.campaign-planning-page .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.campaign-planning-page .ai-loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: 100;
    padding: 2rem;
}

.campaign-planning-page .ai-icon {
    font-size: 3rem;
    color: #4158D0;
    margin-bottom: 1rem;
}

.campaign-planning-page .ai-icon.pulsing {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.campaign-planning-page .loading-bar {
    width: 300px;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 1rem;
}

.campaign-planning-page .loading-progress {
    height: 100%;
    width: 30%;
    background: linear-gradient(90deg, #4158D0 0%, #C850C0 100%);
    animation: progress 1.5s infinite;
    border-radius: 2px;
}

@keyframes progress {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(400%);
    }
}

.campaign-planning-page .budget-input {
    position: relative;
    display: flex;
    align-items: center;
}

.campaign-planning-page .currency {
    position: absolute;
    left: 1rem;
    color: #666;
    font-weight: 500;
}

.campaign-planning-page .budget-input input {
    padding-left: 2rem;
}

.campaign-planning-page .budget-allocation {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.campaign-planning-page .allocation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.campaign-planning-page .allocation-input {
    position: relative;
    display: flex;
    align-items: center;
}

.campaign-planning-page .allocation-input input {
    width: 60px;
    padding-right: 1.5rem;
    text-align: right;
}

.campaign-planning-page .percentage {
    position: absolute;
    right: 0.5rem;
    color: #666;
}

.campaign-planning-page .date-range {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}

.campaign-planning-page .date-input {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.campaign-planning-page .date-input label {
    font-size: 0.9rem;
    color: #666;
}

.campaign-planning-page .budget-summary {
    margin-top: 2rem;
    padding: 1.5rem;
    background: linear-gradient(135deg, rgba(65, 88, 208, 0.05), rgba(200, 80, 192, 0.05));
    border-radius: 12px;
}

.campaign-planning-page .summary-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.campaign-planning-page .summary-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.campaign-planning-page .summary-item .label {
    font-size: 0.9rem;
    color: #666;
}

.campaign-planning-page .summary-item .value {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4158D0;
}

.campaign-planning-page .review-section {
    max-width: 1200px;
    margin: 0 auto;
}

.campaign-planning-page .review-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
}

.campaign-planning-page .review-block {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.campaign-planning-page .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background: linear-gradient(135deg, rgba(65, 88, 208, 0.05), rgba(200, 80, 192, 0.05));
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.campaign-planning-page .review-header h3 {
    margin: 0;
    color: #333;
    font-size: 1.2rem;
}

.campaign-planning-page .edit-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background: linear-gradient(135deg, #4158D0, #C850C0);
    color: white;
    cursor: pointer;
    font-size: 0.9rem;
    transition: opacity 0.2s;
}

.campaign-planning-page .edit-button:hover {
    opacity: 0.9;
}

.campaign-planning-page .review-content {
    padding: 1.5rem;
}

.campaign-planning-page .review-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.campaign-planning-page .review-item:last-child {
    border-bottom: none;
}

.campaign-planning-page .review-item .label {
    color: #666;
    font-size: 0.95rem;
    flex: 0 0 30%;
}

.campaign-planning-page .review-item .value {
    color: #333;
    font-weight: 500;
    text-align: right;
    flex: 0 0 65%;
}

.campaign-planning-page .media-preferences-summary {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.campaign-planning-page .media-preferences-summary h4 {
    margin: 0 0 1rem;
    color: #333;
    font-size: 1.1rem;
}

.campaign-planning-page .allocation-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 0.5rem;
}

.campaign-planning-page .allocation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background: rgba(65, 88, 208, 0.05);
    border-radius: 6px;
}

.campaign-planning-page .submit-section {
    margin-top: 3rem;
    text-align: center;
}

.campaign-planning-page .submit-button {
    padding: 1rem 3rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    background: linear-gradient(135deg, #4158D0, #C850C0);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s, opacity 0.2s;
}

.campaign-planning-page .submit-button:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}

.campaign-planning-page .help-text {
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .campaign-planning-page .form-section {
        padding: 1.5rem;
    }

    .campaign-planning-page .media-types-grid {
        grid-template-columns: 1fr;
    }

    .campaign-planning-page .platform-grid {
        grid-template-columns: 1fr;
    }

    .campaign-planning-page .media-type-card {
        padding: 1.25rem;
    }

    .campaign-planning-page .form-section h2 {
        font-size: 1.6rem;
    }

    .campaign-planning-page .section-description {
        font-size: 1rem;
    }

    .campaign-planning-page .step-indicator {
        gap: 2rem;
        padding: 0 1rem;
    }

    .campaign-planning-page .step:not(:last-child)::after {
        width: calc(100% + 2rem - 36px);
    }

    .campaign-planning-page .step-label {
        font-size: 0.8rem;
    }

    .campaign-planning-page .date-range {
        flex-direction: column;
    }
    
    .campaign-planning-page .budget-allocation {
        grid-template-columns: 1fr;
    }

    .campaign-planning-page .review-item {
        flex-direction: column;
        gap: 0.5rem;
    }

    .campaign-planning-page .review-item .label,
    .campaign-planning-page .review-item .value {
        flex: 1;
        text-align: left;
    }

    .campaign-planning-page .allocation-summary {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1400px) {
    .campaign-planning-page .button-group {
        width: 100%;
        left: 0;
        margin-left: 0;
        padding: 0 2rem;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.campaign-planning-page .form-container {
    animation: fadeIn 0.3s ease-out;
}

.campaign-planning-page .form-group.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
}

.campaign-planning-page .form-group.loading::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border: 2px solid #e5e7eb;
    border-top-color: #6366f1;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    z-index: 1;
}

@keyframes spin {
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.campaign-planning-page .content-slider {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.campaign-planning-page .ai-campaign-form {
    width: 100%;
    flex-shrink: 0;
}

.campaign-planning-page .ai-generated-packages {
    width: 100%;
    flex-shrink: 0;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 0;
    height: auto;
    min-height: 500px;
}

@media (max-width: 1024px) {
    .campaign-planning-page .content-wrapper.show-packages {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        gap: 2rem;
    }

    .campaign-planning-page .show-packages .ai-campaign-form {
        position: relative;
        top: 0;
    }

    .campaign-planning-page .ai-generated-packages {
        transform: translateY(50px);
    }

    .campaign-planning-page .ai-generated-packages.visible {
        transform: translateY(0);
    }
}
