/* src/App.css */

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  transition: margin-left 0.3s ease;
}

.main-content.shifted {
  margin-left: 300px;
}

@media (max-width: 768px) {
  .main-content.shifted {
    margin-left: 0;
  }
}

.toggle-button {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1001;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  display: none; /* Hide the toggle button since we're using the one in Header */
}

.content-wrapper {
  flex: 1;
  padding: 0;
  margin: 0;
}

.content {
  padding: 0;
  margin: 0;
  transition: margin-left 0.3s ease;
}

.content.sidebar-open {
  margin-left: 250px;
}

/* Modal overlay for the login page */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002; /* Ensure the modal is on top of the content */
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}
