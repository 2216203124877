/* src/components/Sidebar.css */

/* Overall styling for the sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
  padding-top: 60px;
  transition: left 0.3s ease;
  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 20px rgba(0, 0, 0, 0.15);
}

.sidebar.open {
  left: 0;
}

/* Main content shifting when sidebar is open */
.main-content.shifted {
  margin-left: 250px; /* Shift content to the right when sidebar is open */
  transition: margin-left 0.3s ease-in-out;
}

/* Toggle button styling */
.sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 24px;
}

/* Navigation menu styling */
.nav-menu {
  flex: 1;
  padding: 1.5rem 1rem;
  overflow-y: auto;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  backdrop-filter: blur(10px);
}

.nav-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.15);
  font-weight: 500;
}

.nav-item svg {
  margin-right: 12px;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.nav-item:hover svg,
.nav-item.active svg {
  color: #ffffff;
}

/* Settings section */
.settings {
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

/* Logout section */
.logout-section {
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.logout-btn {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.logout-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}

.logout-btn svg {
  margin-right: 12px;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.logout-btn:hover svg {
  color: #ffffff;
}

/* Scrollbar styling for the sidebar */
.sidebar::-webkit-scrollbar {
  width: 6px;
}

.sidebar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  padding-left: 2.5rem;
}

.dropdown.open .dropdown-content {
  display: block;
}

.dropdown-item {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  border-radius: 4px;
  margin: 0.25rem 0;
  transition: all 0.2s ease;
}

.dropdown-item:hover {
  color: #4a148c;
  background: rgba(74, 20, 140, 0.05);
}
