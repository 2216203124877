.lead-capture-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    animation: fadeIn 0.3s ease-out;
}

.lead-capture-modal {
    background: white;
    border-radius: 20px;
    padding: 2.5rem;
    max-width: 500px;
    width: 90%;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.4s ease-out;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(65, 88, 208, 0.1);
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;
    transition: all 0.3s ease;
    padding: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.close-icon {
    color: #4158D0;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    display: block;
    line-height: 1;
}

.close-button:hover {
    background: rgba(65, 88, 208, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.close-button:hover .close-icon {
    color: #C850C0;
    transform: rotate(90deg);
}

.modal-content {
    text-align: center;
}

.envelope-icon {
    font-size: 2.5rem;
    color: #4158D0;
    margin-bottom: 1rem;
}

.modal-content h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #4158D0, #C850C0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal-content p {
    color: #666;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.5;
}

.form-group {
    margin-bottom: 1rem;
}

.form-input {
    width: 100%;
    padding: 0.8rem 1rem;
    border: 2px solid #eee;
    border-radius: 10px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-input:focus {
    border-color: #4158D0;
    outline: none;
    box-shadow: 0 0 0 3px rgba(65, 88, 208, 0.1);
}

.submit-button {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 10px;
    background: linear-gradient(135deg, #4158D0, #C850C0);
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(65, 88, 208, 0.3);
}

.submit-button:active {
    transform: translateY(0);
}

.error-message {
    color: #dc3545;
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

.privacy-note {
    margin-top: 1rem;
    font-size: 0.8rem;
    color: #999;
}

.success-message {
    text-align: center;
    padding: 2rem 0;
}

.success-message h2 {
    font-size: 2rem;
    color: #4158D0;
    margin-bottom: 1rem;
}

.success-message p {
    color: #666;
    font-size: 1.1rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 576px) {
    .lead-capture-modal {
        padding: 2rem;
        width: 95%;
    }

    .modal-content h2 {
        font-size: 1.8rem;
    }

    .modal-content p {
        font-size: 1rem;
    }

    .submit-button {
        padding: 0.8rem;
        font-size: 1rem;
    }
}
