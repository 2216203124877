.view-sites-page {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 60px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.page-header {
    text-align: center;
    margin-bottom: 3rem;
    color: #2d3436;
    padding-top: 20px;
}

.page-header h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    background: linear-gradient(45deg, #622ad8, #8c5fdb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.subtitle {
    font-size: 1.1rem;
    color: #636e72;
    margin: 0;
}

.filters-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.basic-filters {
    display: grid;
    grid-template-columns: minmax(200px, 250px) repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    align-items: center;
}

.advanced-filters {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.filter-row {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.advanced-filters-toggle {
    background: #622ad8;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    transition: background-color 0.2s;
    height: 38px;
    white-space: nowrap;
}

.advanced-filters-toggle:hover {
    background: #4f21b0;
}

.filter-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.filter-group label {
    font-size: 14px;
    color: #666;
    font-weight: 500;
}

.filter-group select,
.filter-group input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background: white;
    height: 38px;
}

.filter-group select:focus,
.filter-group input:focus {
    border-color: #622ad8;
    outline: none;
}

.search-bar {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.search-bar input {
    width: 100%;
    padding: 8px 12px 8px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    height: 38px;
}

.search-bar svg {
    position: absolute;
    left: 12px;
    color: #999;
    font-size: 14px;
    z-index: 1;
    pointer-events: none;
}

.results-summary {
    margin: 1rem 0;
    color: #4a4a4a;
    font-size: 1.1rem;
    font-weight: 500;
}

.highlight {
    color: #622ad8;
    font-weight: 600;
}

.media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
}

.media-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
}

.media-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.media-image {
    height: 200px;
    position: relative;
    overflow: hidden;
}

.media-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.media-card:hover .media-image img {
    transform: scale(1.05);
}

.media-type-badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: rgba(98, 42, 216, 0.9);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}

.media-content {
    padding: 1.5rem;
}

.media-content h3 {
    margin: 0 0 1rem;
    font-size: 1.2rem;
    color: #2d3436;
    font-weight: 600;
}

.media-metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.metric {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #636e72;
    font-size: 0.9rem;
}

.metric svg {
    color: #622ad8;
}

.price-tag {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: #f3f0ff;
    color: #622ad8;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.view-details-btn {
    width: 100%;
    padding: 0.8rem;
    background: #622ad8;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-details-btn:hover {
    background: #4a1eb8;
    transform: translateY(-1px);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: 3rem;
}

.pagination-btn {
    padding: 0.8rem 1.5rem;
    border: 2px solid #622ad8;
    background: white;
    color: #622ad8;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination-btn:hover:not(:disabled) {
    background: #622ad8;
    color: white;
}

.pagination-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: #ccc;
    color: #ccc;
}

.pagination-info {
    color: #636e72;
    font-size: 1rem;
}

.current-page {
    color: #622ad8;
    font-weight: 600;
}

.loading {
    text-align: center;
    padding: 4rem;
    color: #636e72;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #622ad8;
    font-weight: 500;
}

.error-message {
    text-align: center;
    padding: 2rem;
    color: #dc3545;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Pagination Styles */
.top-pagination {
    margin: 1rem 0 2rem;
    display: flex;
    justify-content: center;
    background: white;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.pagination-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.page-numbers {
    display: flex;
    gap: 0.5rem;
}

.pagination-button,
.page-number {
    padding: 0.5rem 1rem;
    border: 1px solid #e0e0e0;
    background: white;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #4a4a4a;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-button:hover:not(:disabled),
.page-number:hover:not(:disabled):not(.ellipsis) {
    background: #f0f0f0;
    border-color: #d0d0d0;
}

.pagination-button:disabled,
.page-number:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.page-number.active {
    background: #622ad8;
    color: white;
    border-color: #622ad8;
}

.page-number.ellipsis {
    border: none;
    background: none;
    cursor: default;
    padding: 0.5rem;
}

.pagination-button {
    font-weight: 500;
    min-width: 100px;
}

.pagination-button:disabled {
    background: #f5f5f5;
}

@media (max-width: 768px) {
    .view-sites-page {
        padding: 1rem;
    }

    .page-header h1 {
        font-size: 2rem;
    }

    .filters-section {
        padding: 1rem;
    }

    .basic-filters {
        grid-template-columns: 1fr;
    }
    
    .search-bar {
        grid-column: 1 / -1;
    }

    .media-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .pagination {
        flex-direction: column;
        gap: 1rem;
    }
}

.print-media-details,
.cinema-details,
.influencer-details,
.digital-details,
.traditional-details {
    background-color: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
    margin: 10px 0;
}

.site-details > div {
    margin-bottom: 15px;
}

.site-details p {
    margin: 6px 0;
}

.pricing-details .price {
    font-size: 1.1em;
    color: #2c5282;
    font-weight: 500;
}

.location-details {
    border-left: 3px solid #e2e8f0;
    padding-left: 12px;
    margin: 15px 0;
}

.contact-info {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;
}

/* Add hover effects */
.site-card:hover .site-image img {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

/* Add specific styling for each media type */
.airline-airport-details,
.cinema-details,
.digital-details,
.influencer-details,
.magazine-details,
.newspaper-details,
.btl-details,
.outdoor-details,
.radio-details,
.sports-details,
.television-details,
.agency-partner-details,
.general-details {
    background-color: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
    margin: 10px 0;
    border-left: 3px solid;
}

.airline-airport-details { border-color: #4299e1; }
.cinema-details { border-color: #ed8936; }
.digital-details { border-color: #4fd1c5; }
.influencer-details { border-color: #9f7aea; }
.magazine-details { border-color: #f687b3; }
.newspaper-details { border-color: #718096; }
.btl-details { border-color: #48bb78; }
.outdoor-details { border-color: #38b2ac; }
.radio-details { border-color: #667eea; }
.sports-details { border-color: #f6ad55; }
.television-details { border-color: #fc8181; }
.agency-partner-details { border-color: #b794f4; }
.general-details { border-color: #cbd5e0; }

/* Add hover effect for details sections */
[class*="-details"]:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transform: translateY(-1px);
    transition: all 0.2s ease;
}

.site-card .site-header,
.site-card .description,
.site-card .site-details,
.site-card .contact-info {
    padding: 0 16px;
}

.site-card .site-header {
    padding-top: 16px;
}

.site-card .contact-info {
    padding-bottom: 16px;
}
