/* Modal overlay to cover the full screen */
.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

/* Modal content styling */
.signup-modal {
  background-color: white;
  width: 400px; /* Set a fixed width */
  padding: 30px 20px; /* Add padding for better spacing */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.close-btn:hover {
  color: #333;
}

/* Input field styling */
.signup-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.signup-input:focus {
  outline: none;
  border-color: #622ad8;
  box-shadow: 0 0 0 2px rgba(98, 42, 216, 0.1);
}

/* Sign-up button */
.signup-btn {
  width: 100%;
  padding: 12px;
  background-color: #622ad8;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
}

.signup-btn:hover {
  background-color: #5023b8;
}

.signup-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Error message styling */
.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 14px;
}

/* Switch to login link */
.switch-btn {
  background: none;
  border: none;
  color: #622ad8;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.switch-btn:hover {
  color: #5023b8;
}

/* Terms text styling */
.terms-text {
  font-size: 12px;
  color: #666;
  margin-top: 15px;
}

.terms-text a {
  color: #622ad8;
  text-decoration: none;
}

.terms-text a:hover {
  text-decoration: underline;
}

/* Divider styling */
.divider {
  margin: 15px 0;
  text-align: center;
  position: relative;
}

.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    position: relative;
}

.modal-content h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.form-group input:focus {
    border-color: #4CAF50;
    outline: none;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.submit-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
}

.submit-btn:hover {
    background-color: #45a049;
}

.submit-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    text-align: center;
}

.switch-text {
    text-align: center;
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
}

.switch-btn {
    background: none;
    border: none;
    color: #4CAF50;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
    margin-left: 0.5rem;
}

.switch-btn:hover {
    text-decoration: underline;
}

.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    line-height: 1;
}

.close-btn:hover {
    color: #333;
}
