/* src/pages/AddSitePage.css */

/* General styles */
.add-site-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.add-site-page h2 {
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

.form-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-section h3 {
    color: #4a148c;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.submit-btn {
    background: #4a148c;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background: #6a1b9a;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    min-width: 300px;
    text-align: center;
}

.dialog.success {
    border-top: 4px solid #4caf50;
}

.dialog.error {
    border-top: 4px solid #f44336;
}

.dialog h3 {
    margin-bottom: 15px;
    color: #333;
}

.dialog button {
    background: #4a148c;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .add-site-page {
        padding: 10px;
    }
}
