.media-detail-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 80px; /* Account for header height (60px) plus some spacing */
}

/* Header Section */
.header-section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.media-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.media-title h1 {
  margin: 0 0 8px 0;
  font-size: 32px;
  color: #1a1a1a;
}

.location {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 16px;
}

.media-logo img {
  max-width: 120px;
  height: auto;
  border-radius: 8px;
}

/* Attributes Section */
.attributes-section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.attributes-section h2 {
  margin: 0 0 20px 0;
  color: #1a1a1a;
}

.attributes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.attribute-card {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.2s;
}

.attribute-card:hover {
  transform: translateY(-2px);
}

.attribute-card svg {
  width: 20px;
  height: 20px;
  color: #007bff;
}

.attribute-content {
  flex: 1;
}

.attribute-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.attribute-name {
  font-size: 14px;
  color: #666;
}

.attribute-tooltip {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

/* Media Options Section */
.media-options-section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.option-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s;
  border: 2px solid transparent;
}

.option-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option-card.selected {
  border-color: #622ad8;
  background: #fff;
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.option-header h3 {
  margin: 0;
  font-size: 20px;
  color: #1a1a1a;
}

.option-type {
  background: #622ad8;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
}

.option-price {
  margin-bottom: 16px;
}

.price-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.price-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.option-attributes {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-attribute {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.attribute-label {
  color: #666;
}

/* Selected Option Details */
.selected-option-details {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.specs-section,
.artworks-section,
.pricing-units-section {
  margin-bottom: 24px;
}

.specs-section h3,
.artworks-section h3,
.pricing-units-section h3 {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.specs-section h3 svg,
.artworks-section h3 svg,
.pricing-units-section h3 svg {
  color: #622ad8;
}

.spec-group {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.spec-group h4 {
  margin: 0 0 12px 0;
  color: #1a1a1a;
}

.spec-fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
}

.spec-field {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.field-key {
  color: #666;
}

.artworks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.artwork-card {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.artwork-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.artwork-name {
  padding: 12px;
  font-size: 14px;
  color: #666;
}

.pricing-units-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.pricing-unit {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
}

.pricing-unit h4 {
  margin: 0 0 8px 0;
  color: #1a1a1a;
}

.pricing-unit select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

/* Stats Section */
.stats-section {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-group {
  margin-bottom: 2rem;
}

.stat-group h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 6px;
  transition: transform 0.2s;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-name {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.stat-value {
  color: #2c3e50;
  font-size: 1.1rem;
  font-weight: 600;
}

.stat-item a {
  color: #007bff;
  text-decoration: none;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.stat-item a:hover {
  text-decoration: underline;
}

/* Links Section */
.links-section {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.link-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.2s;
}

.link-item:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

.link-name {
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.link-value {
  color: #666;
  font-size: 0.9rem;
}

/* About Section */
.about-section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  margin: 0 0 20px 0;
  color: #1a1a1a;
}

.content {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* FAQs Section */
.faqs-section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faqs-section h2 {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 20px 0;
  color: #1a1a1a;
}

.faqs-section h2 svg {
  color: #622ad8;
}

.faq-item {
  margin-bottom: 24px;
}

.faq-item h3 {
  font-size: 18px;
  color: #1a1a1a;
  margin: 0 0 12px 0;
}

.faq-item div {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Loading and Error States */
.loading,
.error {
  margin-top: 80px;
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

/* Responsive Design */
@media (max-width: 768px) {
  .media-detail-page {
    padding: 12px;
  }

  .media-header {
    flex-direction: column;
    text-align: center;
    gap: 16px;
  }

  .media-title h1 {
    font-size: 24px;
  }

  .attributes-grid,
  .options-grid,
  .artworks-grid,
  .pricing-units-grid {
    grid-template-columns: 1fr;
  }

  .spec-fields {
    grid-template-columns: 1fr;
  }
}
