/* src/components/Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #4158D0 0%, #C850C0 100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 2rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  min-width: 200px;
}

.header-right {
  display: flex;
  align-items: center;
  min-width: 200px;
  justify-content: flex-end;
}

/* Search bar styles */
.search-form {
  flex: 1;
  max-width: 500px;
  margin: 0 2rem;
  position: relative;
}

.search-container {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 30px;
  padding: 0.3rem 0.3rem 0.3rem 1.2rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  height: 40px;
}

.search-container:focus-within {
  background: rgba(255, 255, 255, 0.18);
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
  transform: translateY(1px);
}

.search-input {
  flex: 1;
  background: transparent;
  border: none;
  padding: 0.3rem 0.8rem;
  color: white;
  font-size: 0.95rem;
  outline: none;
  font-weight: 400;
  letter-spacing: 0.3px;
  width: 100%;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.search-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: rgba(255, 255, 255, 0.9);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.search-button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transform: scale(1.05);
}

.search-button:active {
  transform: scale(0.95);
}

/* Logo and other header elements */
.logo-link {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.logo-link h1 {
  margin: 0;
  font-size: 1.4rem;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.8));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sidebar-toggle {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.sidebar-toggle:hover {
  color: #ffffff;
}

.login-btn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.5rem 1.2rem;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.login-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.4);
  transform: translateY(-1px);
}

.login-btn:active {
  transform: translateY(1px);
}

.profile-section {
  display: flex;
  align-items: center;
}

.profile-link {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.3s ease;
}

.profile-link:hover {
  background: rgba(255, 255, 255, 0.25);
}

.user-name {
  font-size: 0.9rem;
  font-weight: 500;
}

/* Responsive styles */
@media (max-width: 968px) {
  .search-form {
    max-width: 400px;
    margin: 0 1.5rem;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 1rem;
  }

  .search-form {
    max-width: 300px;
    margin: 0 1rem;
  }

  .search-input {
    font-size: 0.9rem;
  }

  .header-left, .header-right {
    min-width: 150px;
  }
}

@media (max-width: 576px) {
  .search-form {
    max-width: none;
    margin: 0 0.5rem;
  }

  .header-left, .header-right {
    min-width: 100px;
  }

  .search-container {
    height: 36px;
  }

  .search-button {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }
}

/* Add padding to body to prevent content from hiding under fixed header */
body {
  padding-top: 64px;
}
