.analytics-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 80px;
}

.analytics-header {
  text-align: center;
  margin-bottom: 3rem;
}

.analytics-header h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #4158D0, #C850C0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.analytics-header p {
  font-size: 1.2rem;
  color: #666;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.metric-card {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.metric-card:hover {
  transform: translateY(-5px);
}

.metric-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.metric-content h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.metric-numbers {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.metric-value {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.metric-trend {
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
}

.metric-trend.positive {
  color: #28a745;
  background: rgba(40, 167, 69, 0.1);
}

.metric-trend.negative {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.1);
}

.metric-description {
  color: #666;
  font-size: 0.9rem;
}

.insights-section {
  margin-bottom: 4rem;
}

.insights-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.insights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.insight-card {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.insight-card:hover {
  transform: translateY(-5px);
}

.insight-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.insight-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.insight-header h3 {
  margin: 0;
  color: #333;
}

.insight-description {
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.insight-stats {
  font-weight: bold;
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  background: rgba(65, 88, 208, 0.1);
  border-radius: 20px;
  display: inline-block;
}

.charts-section {
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.chart-container {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.chart-container h3 {
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

.chart-wrapper {
  height: 300px;
  position: relative;
}

.analytics-cta {
  text-align: center;
  padding: 3rem;
  background: linear-gradient(45deg, rgba(65, 88, 208, 0.05), rgba(200, 80, 192, 0.05));
  border-radius: 15px;
}

.analytics-cta h2 {
  color: #333;
  margin-bottom: 1rem;
}

.analytics-cta p {
  color: #666;
  margin-bottom: 2rem;
}

.cta-button {
  background: linear-gradient(45deg, #4158D0, #C850C0);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .analytics-page {
    padding: 1rem;
  }

  .analytics-header h1 {
    font-size: 2rem;
  }

  .metrics-grid,
  .insights-grid {
    grid-template-columns: 1fr;
  }

  .charts-section {
    grid-template-columns: 1fr;
  }
  
  .chart-wrapper {
    height: 250px;
  }

  .analytics-cta {
    padding: 2rem 1rem;
  }
}
